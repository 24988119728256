import React from 'react'
import teamworkSmiley from '../images/teamwork-smiley.png'
import xing from '../images/xing.png'
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
import linkedin from '../images/linkedin.png'

const Footer = () => {

    return (


        <footer className="et-l et-l--footer">
            <div className="et_builder_inner_content et_pb_gutters3">
                <div className="et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular">


                    <div className="et_pb_row et_pb_row_0_tb_footer et_pb_equal_columns">
                        <div
                            className="et_pb_column et_pb_column_1_3 et_pb_column_0_tb_footer  et_pb_css_mix_blend_mode_passthrough">


                            <div
                                className="et_pb_module et_pb_text et_pb_text_0_tb_footer et_clickable  et_pb_text_align_left et_pb_bg_layout_light">


                                <div className="et_pb_text_inner"><a href="https://teamwork-germany.com/impressum/"
                                                                     target="_blank"
                                                                     rel="noreferrer">Impressum</a></div>
                            </div>

                            <div
                                className="et_pb_module et_pb_text et_pb_text_1_tb_footer et_clickable  et_pb_text_align_left et_pb_bg_layout_light">


                                <div className="et_pb_text_inner"><a href="https://teamwork-germany.com/datenschutz/"
                                                                     target="_blank"
                                                                     rel="noreferrer">Datenschutz</a></div>
                            </div>

                        </div>

                        <div
                            className="et_pb_column et_pb_column_1_3 et_pb_column_1_tb_footer  et_pb_css_mix_blend_mode_passthrough">


                            <div className="et_pb_module et_pb_image et_pb_image_0_tb_footer">


                               <span className="et_pb_image_wrap "><img loading="lazy"
                                                                        src={teamworkSmiley}
                                                                        alt="" title="teamwork-smiley" height="auto"
                                                                        width="auto"/>  </span>
                                <span className="text_under_smiley"><a href="https://teamwork-germany.com/"
                                                                       target="_blank"
                                                                       rel="noreferrer">Zur TEAMWORK-Hauptseite</a></span>
                            </div>

                        </div>

                        <div
                            className="et_pb_column et_pb_column_1_3 et_pb_column_2_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">


                            <div className="et_pb_module et_pb_image et_pb_image_1_tb_footer">


                                <a href="https://www.xing.com/companies/teamworkinstoreservicesgmbh" rel="noreferrer"
                                   target="_blank"><span
                                    className="et_pb_image_wrap "><img loading="lazy"
                                                                       src={xing}
                                                                       alt="" title="xing" height="40px"
                                                                       width="40px"/></span></a>

                            </div>
                            <div className="et_pb_module et_pb_image et_pb_image_2_tb_footer">


                                <a href="https://www.linkedin.com/company/teamwork-instore-services-gmbh/?originalSubdomain=de"
                                   rel="noreferrer" target="_blank"><span className="et_pb_image_wrap "><img
                                    loading="lazy"
                                    src={linkedin}
                                    alt="" title="linkedin"
                                    height="40px"
                                    width="40px"/></span></a>

                            </div>
                            <div className="et_pb_module et_pb_image et_pb_image_3_tb_footer">


                                <a href="https://www.instagram.com/teamworkinstoreservices/" rel="noreferrer"
                                   target="_blank"><span
                                    className="et_pb_image_wrap "><img loading="lazy"
                                                                       src={instagram}
                                                                       alt="" title="instagram" height="40px"
                                                                       width="40px"/></span></a>

                            </div>
                            <div className="et_pb_module et_pb_image et_pb_image_4_tb_footer">


                                <a href="https://de-de.facebook.com/teamwork.germany/" rel="noreferrer" target="_blank"><span
                                    className="et_pb_image_wrap "><img loading="lazy"
                                                                       src={facebook}
                                                                       alt="" title="facebook" height="40px"
                                                                       width="40px"/></span></a>

                            </div>
                        </div>


                    </div>


                </div>
            </div>

        </footer>

    )


}
export default Footer;
