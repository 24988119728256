import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import "./App.css";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";

import Header from './Header/Header'
import Navigation from './Navigation/Navigation'
import Footer from './Footer/Footer'
import Store from './Store/Store'


ReactDOM.render(
    //<React.StrictMode>
    <Store>
        <Router>
            <Header/>
            <Navigation/>
            <Footer/>
        </Router>
    </Store>,
    //</React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
