import {authInstance} from "../http-common";
import authHeader from "../auth-header";

const register = (username, password) => {
    return authInstance.post("/register", {
        username,
        password,
    });
};

const login = (username, password) => {
    return authInstance.post("/login", {
        username,
        password,
    })
        .then((response) => {
            if (response.data.accessToken && response.data.rights !== "ad") {
                localStorage.setItem("user", JSON.stringify(response.data));
            } else {
                return false;
            }

            return response.data;
        });
};

const changePassword = (username, password) => {
    return authInstance.post("/changePassword", {
        username,
        password,
    }, {headers: authHeader()})
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const forgotPassword = (username) => {
    return authInstance.post("/forgotPasswordBackend", {
        username
    })
        .then((response) => {

            return response.data;
        });
};

const resetPassword = (token, password) => {
    return authInstance.post(`/resetPassword/${token}`, {
        token,
        password
    }, {headers: authHeader()})
        .then((response) => {

            return response.data;
        });
};

const loginSchulung = (password) => {
    return authInstance.post("/loginSchulung", {
        password
    })
        .then((response) => {

            return response.data;
        });
};

const profile = (email, birthdate) => {
    return authInstance.post("/profile", {
        email,
        birthdate,
    })
        .then((response) => {

            return response.data;
        });
};

const logout = () => {
    return localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const isLoggedIn = () => {
    return !!localStorage.getItem("user")

}

const refreshToken = () => {

    const user = getCurrentUser()
    if (user && user.refreshToken) {

        const tokenParts = JSON.parse(atob(user.refreshToken.split('.')[1]));

        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
            authInstance
                .post('/refreshToken', {refreshToken: user.refreshToken})
                .then((response) => {

                    localStorage.setItem('user', JSON.stringify(response.data));

                    return response.data

                })
                .catch(() => {

                    localStorage.removeItem("user");
                    window.location.href = 'login';
                });
        } else {

            localStorage.removeItem("user");
            window.location.href = 'login';
        }
    } else {
        localStorage.removeItem("user");
        window.location.href = 'login';
    }
}

const isAdmin = () => {
    let user = JSON.parse(localStorage.getItem("user"));

    let rights = user.rights

    return rights === "admin";

}

const AuthService = {
    register,
    profile,
    isLoggedIn,
    changePassword,
    refreshToken,
    forgotPassword,
    resetPassword,
    isAdmin,
    login,
    loginSchulung,
    logout,
    getCurrentUser
}
export default AuthService
