import React, {useEffect, useRef, useState} from 'react'
import {NavDropdown} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import AuthService from "../services/AuthService";
import logo from '../images/teamwork-logo.png'


const Header = () => {

    const [expanded, setExpanded] = useState(false);
    const [scrollTop, setScrollTop] = useState(false);
    let [user, setUser] = useState(AuthService.getCurrentUser());

    const fieldRef = useRef(null);


    useEffect(() => {
        fieldRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
        setScrollTop(false)
    }, [scrollTop])


    useEffect(() => {

        setUser(AuthService.getCurrentUser())

    }, []);

    const logOut = () => {
        AuthService.logout();
        setUser(null)
    };

    return (

        <header className="et-l et-l--header" ref={fieldRef}>
            <div className="et_builder_inner_content et_pb_gutters3">
                <div className="et_pb_section et_pb_section_0_tb_header et_section_regular et_pb_section--fixed">


                    <div className="et_pb_row et_pb_row_0_tb_header et_pb_row--with-menu">
                        <div
                            className="et_pb_column et_pb_column_1_3 et_pb_column_0_tb_header nav-two-column  et_pb_css_mix_blend_mode_passthrough">


                            <div className="et_pb_module et_pb_image et_pb_image_0_tb_header">


                                <span className="et_pb_image_wrap"> <NavLink onClick={() => {
                                    setExpanded(false);
                                    setScrollTop(true)
                                }} exact to={"/"} href='/'><img
                                    src={logo}
                                    width="auto"
                                    height="auto"
                                    alt="Teamwork Instore Services"
                                /></NavLink></span>
                            </div>
                        </div>

                        <div
                            className="et_pb_column et_pb_column_2_3 et_pb_column_1_tb_header nav-two-column  et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column--with-menu">


                            <div
                                className="et_pb_module et_pb_menu et_pb_menu_0_tb_header et_pb_bg_layout_light  et_pb_text_align_right et_dropdown_animation_fade et_pb_menu--without-logo et_pb_menu--style-left_aligned">


                                <div className="et_pb_menu_inner_container clearfix">

                                    <div className="et_pb_menu__wrap">
                                        <div className="et_pb_menu__menu">
                                            <nav className="et-menu-nav">
                                                {user &&
                                                    <ul id="menu-hauptmenue-rework" className="et-menu nav">
                                                        {user && !user.isFirstLogin &&
                                                           <> <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                                <NavLink onClick={() => setExpanded(false)}
                                                                         activeClassName="active" to={"/table"}
                                                                         href='/table'
                                                                >Alle Bewerber</NavLink></li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                            <NavLink onClick={() => setExpanded(false)}
                                                            activeClassName="active" to={"/tableVerified"}
                                                            href='/tableVerified'
                                                            >Verifizierte Bewerber</NavLink></li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                            <NavLink onClick={() => setExpanded(false)}
                                                            activeClassName="active" to={"/flyerGenerated"}
                                                            href='/flyerGenerated'
                                                            >Generierte Flyer</NavLink></li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                            <NavLink onClick={() => setExpanded(false)}
                                                            activeClassName="active" to={"/addBewerber"}
                                                            href='/addBewerber'
                                                            >Bewerber hinzufügen</NavLink></li>

                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                            <NavLink onClick={() => setExpanded(false)}
                                                            activeClassName="active" to={"/import"}
                                                            href='/import'
                                                            >Bewerber importieren</NavLink></li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                            <NavLink onClick={() => setExpanded(false)}
                                                            activeClassName="active" to={"/generatoren"}
                                                            href='/generatoren'
                                                            >Generatoren</NavLink></li>
                                                        </>}
                                                        {user &&
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                                <NavDropdown title={user.username}
                                                                             id="collasible-nav-dropdown">
                                                                    {!user.isFirstLogin &&
                                                                        <>
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="active" exact
                                                                                 to={"/user"} href='/user'
                                                                                 onClick={() => {
                                                                                     setExpanded(false)
                                                                                 }}>Benutzerverwaltung</NavLink>
                                                                        <NavLink className="nav-link"
                                                                        activeClassName="active" exact
                                                                        to={"/changePassword"}
                                                                        href='/changePassword'
                                                                        onClick={() => {
                                                                        setExpanded(false)
                                                                    }}>Passwort ändern</NavLink>
                                                                        </>
                                                                    }
                                                                    <NavLink className="nav-link"
                                                                             activeClassName="active" exact
                                                                             to={"/login"} href='/login'
                                                                             onClick={() => {
                                                                                 logOut();
                                                                                 setExpanded(false)
                                                                             }}>Logout</NavLink>
                                                                </NavDropdown>
                                                            </li>
                                                        }
                                                    </ul>
                                                }
                                            </nav>
                                        </div>


                                        <div className="et_mobile_nav_menu navbar-light">
                                            <button type="button" onClick={() => setExpanded(!expanded)}
                                                    aria-label="Toggle navigation"
                                                    className="navbar-toggler collapsed"><span
                                                className="navbar-toggler-icon"/></button>
                                            <span className="mobile_menu_bar"/>
                                            {user && expanded &&

                                                <ul id="mobile_menu1" className="et_mobile_menu">
                                                    {user && !user.isFirstLogin &&
                                                        <>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                        <NavLink onClick={() => setExpanded(false)} to={"/table"}
                                                                 href='/table'
                                                        >Alle Bewerber</NavLink></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                        <NavLink onClick={() => setExpanded(false)}
                                                                 activeClassName="active" to={"/tableVerified"}
                                                                 href='/tableVerified'
                                                        >Verifizierte Bewerber</NavLink></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                        <NavLink onClick={() => setExpanded(false)}
                                                                 to={"/addBewerber"} href='/addBewerber'
                                                        >Bewerber hinzufügen</NavLink></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                        <NavLink onClick={() => setExpanded(false)}
                                                                 activeClassName="active" to={"/flyerGenerated"}
                                                                 href='/flyerGenerated'
                                                        >Generierte Flyer</NavLink></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                        <NavLink onClick={() => setExpanded(false)} to={"/import"}
                                                                 href='/import'
                                                        >Bewerber importieren</NavLink></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                        <NavLink onClick={() => setExpanded(false)}
                                                                 activeClassName="active" to={"/generatoren"}
                                                                 href='/generatoren'
                                                        >Generatoren</NavLink></li>
                                                    </>}
                                                    {user &&
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item">
                                                            <NavDropdown title={user.username}
                                                                         id="collasible-nav-dropdown">
                                                                {!user.isFirstLogin &&
                                                                    <>
                                                                    <NavLink className="nav-link"
                                                                             activeClassName="active" exact
                                                                             to={"/user"} href='/user'
                                                                             onClick={() => {
                                                                                 setExpanded(false)
                                                                             }}>Benutzerverwaltung</NavLink>
                                                                    <NavLink className="nav-link"
                                                                    activeClassName="active" exact
                                                                    to={"/changePassword"}
                                                                    href='/changePassword'
                                                                    onClick={() => {
                                                                    setExpanded(false)
                                                                }}>Passwort ändern</NavLink>
                                                                    </>
                                                                }
                                                                <NavLink className="nav-link"
                                                                         activeClassName="active"
                                                                         exact to={"/login"} href='/login'
                                                                         onClick={() => {
                                                                             logOut();
                                                                             setExpanded(false)
                                                                         }}>Logout</NavLink>
                                                            </NavDropdown>
                                                        </li>
                                                    }
                                                </ul>
                                            }


                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>


                    </div>


                </div>
            </div>

        </header>

    )


}
export default Header;
