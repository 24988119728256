import axios from "axios";

const authInstance = axios.create({
    baseURL: "https://bewerber.volleregale.de",
    timeout: 10000,
    headers: {
        "Content-type": "application/json"
    },

});

const instance = axios.create({
    baseURL: "https://bewerber.volleregale.de",
    timeout: 10000,
    headers: {
        "Content-type": "application/json"
    },

});


authInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;

        // Prevent infinite loops
        if (error.response.status === 401 && originalRequest.url === authInstance.defaults.baseURL + '/refreshToken') {
            localStorage.removeItem("user");

            window.location.href = 'login';
            return Promise.reject(error);
        }

        if (error.response.status === 401 && originalRequest && !originalRequest.__isRetryRequest) {

            originalRequest._retry = true;


            const user = JSON.parse(localStorage.getItem("user"))


            if (user.refreshToken) {

                const tokenParts = JSON.parse(atob(user.refreshToken.split('.')[1]));

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);

                if (tokenParts.exp > now) {
                    return authInstance
                        .post(authInstance.defaults.baseURL + '/refreshToken', {refreshToken: user.refreshToken})
                        .then((response) => {

                            localStorage.setItem('user', JSON.stringify(response.data));

                            originalRequest.headers['x-access-token'] = response.data.accessToken

                            return authInstance(originalRequest)

                        })
                        .catch(() => {

                            return Promise.reject(error);
                        });
                } else {

                    localStorage.removeItem("user");
                    window.location.href = 'login';
                }
            } else {

                localStorage.removeItem("user");
                window.location.href = 'login';
            }


        }
        return Promise.reject(error);


    }
);


export {authInstance, instance}
