import React, {lazy, Suspense} from 'react'
import {Route, Switch} from "react-router-dom";
import Loading from "../Loading/Loading";


const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const Table = lazy(() => import("../components/Table/Table"));
const TableVerified = lazy(() => import("../components/Table/TableVerified"));
const FlyerList = lazy(() => import("../components/Flyer/FlyerList"));
const UserList = lazy(() => import("../components/User/UserList"));
const AddBewerberBackend = lazy(() => import("../components/AddBewerberForm/AddBewerberBackend"));
const ImportBewerber = lazy(() => import("../components/Import/ImportBewerber"));
const Generatoren = lazy(() => import("../components/Generatoren/Generatoren"));
const EBayFBInstaGenerator = lazy(() => import("../components/Generatoren/EBayFBInsta/EBayFBInstaGenerator"));
const FlyerGenerator = lazy(() => import("../components/Generatoren/FlyerGenerator/FlyerGenerator"));
const InstaStoryGenerator = lazy(() => import("../components/Generatoren/InstaStory/InstaStoryGenerator"));
const GrafikXingLinkedIn = lazy(() => import("../components/Generatoren/GrafikXingLinkedIn/GrafikXingLinkedInGenerator"));
const GrafikWhatsApp = lazy(() => import("../components/Generatoren/GrafikWhatsapp/GrafikWhatsappGenerator"));
const GrafikChatgruppe = lazy(() => import("../components/Generatoren/GrafikChatgruppe/GrafikChatgruppeGenerator"));
const Login = lazy(() => import("../components/Login/Login"));
const ChangePassword = lazy(() => import("../components/Login/ChangePassword"));
const ForgotPassword = lazy(() => import("../components/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("../components/Login/ResetPassword"));
const SchulungVideos = lazy(() => import("../components/SchulungVideos/SchulungVideos"));


const Navigation = () => {


    return (
        <Suspense fallback={<Loading/>}>
            <Switch>
                <ProtectedRoute exact path={"/"} component={Table}/>
                <Route path={"/login"} component={Login}/>
                <ProtectedRoute path={"/changePassword"} component={ChangePassword}/>
                <Route path={"/forgotPassword"} component={ForgotPassword}/>
                <Route path={"/resetPassword"} component={ResetPassword}/>
                <ProtectedRoute path={"/table"} component={Table}/>
                <ProtectedRoute path={"/tableVerified"} component={TableVerified}/>
                <ProtectedRoute path={"/addBewerber"} component={AddBewerberBackend}/>
                <ProtectedRoute path={"/flyerGenerated"} component={FlyerList}/>
                <ProtectedRoute path={"/user"} component={UserList}/>
                <ProtectedRoute path={"/import"} component={ImportBewerber}/>
                <ProtectedRoute path={"/generatoren"} component={Generatoren}/>
                <ProtectedRoute path={"/EBayFBInsta-Generator/"} component={EBayFBInstaGenerator}/>
                <ProtectedRoute path={"/FlyerGenerator/"} component={FlyerGenerator}/>
                <ProtectedRoute path={"/InstaStoryGenerator/"} component={InstaStoryGenerator}/>
                <ProtectedRoute path={"/GrafikWhatsApp/"} component={GrafikWhatsApp}/>
                <ProtectedRoute path={"/GrafikXingLinkedIn/"} component={GrafikXingLinkedIn}/>
                <ProtectedRoute path={"/GrafikChatgruppe/"} component={GrafikChatgruppe}/>
                <ProtectedRoute path={"/schulung"} component={SchulungVideos}/>
                <Route path='*' component={Login}/>
            </Switch>
        </Suspense>


    )

}

export default Navigation;
