import React from "react";

import {Spinner} from "react-bootstrap";

const Loading = () => {

    return (
        <div className="header-content-container center border-content">
            <div className="et_pb_section et_pb_section_1 et_pb_with_background et_section_regular">


                <div className="et_pb_row et_pb_row_0">
                    <div
                        className="et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">


                        <div
                            className="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light et_had_animation">


                            <div className="et_pb_text_inner"><h2><span>Lädt...</span></h2></div>
                        </div>


                        <div
                            className="et_pb_module et_pb_divider et_pb_divider_0 et_pb_divider_position_center et_pb_space et_had_animation">
                            <div className="et_pb_divider_internal"/>
                        </div>
                    </div>


                </div>

                <div className="et_pb_row et_pb_row_0">
                    <div
                        className="et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">


                        <div
                            className="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light et_had_animation">


                            <div className="et_pb_text_inner text_loading loading"><Spinner
                                animation="border spinner-loading"/></div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    );
};

export default Loading;
